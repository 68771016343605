<template>
  <div>

    <div class="top-timer">
      <div class="l">
        <!--
           Nowkithe  string    // 当前期号 
 Closetime int64     // 距离封盘的时间 
 Opentime  int64     // 开盘时间 
 Totaltime int64     // 一期的所有时长 用来做进度条总长度的 
 Bantime   int64     // 封盘时间 
 State     RoomState // 房间状态
  -->
        <span class="jump ">{{currentGame.Gamename}}</span>
        <i v-if="opentime.isjiashiorder==1" class="el-icon-right"></i>
        <i v-else>—</i>
        <span>{{$route.meta}}</span>
        <!-- <span class="s1">今日输赢：</span>
        <b class="b1">{{winlost}}</b> -->
        <span v-if="jspanOpen" class="jiashipan" style="margin-left:42px">加时盘</span>
      </div>
      <div class="r">
        <span class="s2">{{opentime.expect}}期</span>
        <span>距离封盘：</span>
        <b class="b1" id="countdownTimer">{{cmm+':'}}{{css}}</b>
        <span class="s3">距离开奖：</span>
        <b class="b2">{{mm+':'}}{{ss}}</b>
        <span class="s4">{{count}}秒</span>
      </div>
    </div>

  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "",
  props: [""],
  data() {
    return {
      opentime: {},

      mm: "00",
      ss: "00",

      cmm: "00",
      css: "00",
      isOpen: true,
      winlost: "",
      count: 10,
      time3: null,
      dingshiqi: null,
      Bantime: "",
      Closetime: "",
      jspanOpen: false,

      isJS: false,
    };
  },
  watch: {
    jspanOpen(val) {
      this.$store.commit("SETJSPANOPEN", val);
    },
    Closetime: {
      immediate: true,
      handler(val) {
        if (val <= 0) {
          this.isOpen = true;
          this.$nextTick(() => {
            this.$store.commit("CLOSEPAN");
          });
        }
      },
    },
    "currentGame.roomeng": {
      handler(val) {
        if (val) {
          this.getopentime();
        }
      },
    },
    currentGame: {
      immediate: true,
      handler(game) {
        if (game.hasOwnProperty("roomeng")) {
          this.jspanOpen = false;
          this.$store.commit("SETJSPANOPEN", false);
          // console.log("游戏变了啊", game);
          this.mm = "00";
          this.ss = "00";
          this.isOpen = true;
          this.cmm = "00";
          this.css = "00";
        }
      },
    },
  },

  computed: {
    ...mapState(["currentGame", "userInfo"]),
  },
  components: {},

  created() {
    // 今日输赢
    // this.getwinlost();
  },

  beforeDestroy() {
    this.mm = "00";
    this.ss = "00";
    this.cmm = "00";
    this.css = "00";

    window.clearInterval(window.time);
  },

  mounted() {
    // 假数据时间 10s
    setInterval(() => {
      if (this.count > 0) {
        this.count--;
      } else {
        this.count = 10;
      }
    }, 1000);
    window.time = setInterval(() => {
      if (this.Bantime > 20 && this.Bantime % 20 == 0) {
        if (this.currentGame.roomeng) {
          this.getopentime();
        }
      }
      if (this.Bantime < 20 && this.Bantime > 0 && this.Bantime % 3 == 0) {
        if (this.currentGame.roomeng) {
          this.getopentime();
        }
      }
      if (this.Bantime <= 0) {
        if (this.currentGame.roomeng) {
          this.getopentime();
        }
      }

      if (this.Bantime > 0) {
        this.Bantime--;
        this.renderBantime();
      } else {
        this.Bantime = 0;
        this.mm = "00";
        this.ss = "00";
      }
      if (this.Closetime > 0) {
        this.Closetime--;
        this.renderClosetime();
      } else {
        this.Closetime = 0;
        this.isOpen = true;
        this.cmm = "00";
        this.css = "00";
      }
    }, 1000);
  },
  methods: {
    // 获取今日输赢
    getwinlost() {
      let obj = {
        uuid: this.userInfo.Uuid,
        sid: this.userInfo.Sid,
      };
      this.$http.post("gettodaywinlost", obj).then((res) => {
        if (res.Status) {
          // console.log(res, "输赢");
          this.winlost = res.Msg;
        } else {
          this.$message.error(res.Msg);
        }
      });
    },
    // 获取开奖时间
    getopentime() {
      let obj = {
        uuid: this.userInfo.Uuid,
        sid: this.userInfo.Sid,
        roomeng: this.currentGame.roomeng,
      };
      // getopentime
      this.$http.post("getcommonopentime", obj).then((res) => {
        if (res.Status) {
          let resObj = JSON.parse(res.Msg);

          this.opentime = resObj;

          // 不是日级的 不考虑 加时盘
          if (
            this.currentGame.frequency != "day" ||
            resObj.isjiashiorder == 0
          ) {
            this.jspanOpen = false;
            this.$store.commit("SETJSPANOPEN", false);
            // if (this.opentime.State == 4) {
            //   this.$store.commit("SETZEZHAO");
            // } else {
            //   this.$store.commit("CUTZEZHAO");
            // }
            this.Bantime = resObj.bantime;
            this.Closetime =
              this.$route.meta == "特码"
                ? resObj.temaclosetime
                : resObj.closetime;
            if (this.Closetime == 0) {
              this.$store.commit("SETZEZHAO");
            }

            if (this.Closetime > 0 && this.isOpen) {
              this.isOpen = false;

              this.$store.commit("CUTZEZHAO");
              this.$store.commit("OPENPAN");
            }
          } else {
            this.Bantime = resObj.bantime;

            // 日级的 考虑加时盘
            // 六合
            if (this.currentGame.Gametype == "hk6") {
              //  特码
              if (this.$route.meta == "特码") {
                if (resObj.temaclosetime == resObj.jstemaclosetime) {
                  this.isJS = false;
                } else {
                  this.isJS = true;
                }
                if (
                  this.isJS &&
                  resObj.temaclosetime == 0 &&
                  resObj.jstemaclosetime > 0
                ) {
                  this.Closetime = resObj.jstemaclosetime;
                  this.jspanOpen = true;
                } else {
                  this.Closetime = resObj.temaclosetime;
                  this.jspanOpen = false;
                }
              } else {
                //平码
                if (resObj.temaclosetime == resObj.jsclosetime) {
                  this.isJS = false;
                } else {
                  this.isJS = true;
                }
                if (
                  this.isJS &&
                  resObj.closetime == 0 &&
                  resObj.jsclosetime > 0
                ) {
                  this.Closetime = resObj.jsclosetime;
                  this.jspanOpen = true;
                } else {
                  this.Closetime = resObj.closetime;
                  this.jspanOpen = false;
                }
              }
            } else {
              // 其他游戏
              if (resObj.closetime == resObj.jsclosetime) {
                this.isJS = false;
              } else {
                this.isJS = true;
              }
              if (
                this.isJS &&
                resObj.closetime == 0 &&
                resObj.jsclosetime > 0
              ) {
                this.Closetime = resObj.jsclosetime;
                this.jspanOpen = true;
              } else {
                this.Closetime = resObj.closetime;
                this.jspanOpen = false;
              }
            }
            if (this.Closetime > 0 && this.isOpen) {
              this.isOpen = false;
              // this.$nextTick(() => {
              this.$store.commit("CUTZEZHAO");
              this.$store.commit("OPENPAN");
            }
            if (this.Closetime == 0) {
              this.$store.commit("SETZEZHAO");
            }
          }
        } else {
          this.$message.error(res.Msg);
        }
      });
    },

    // 新判断封盘时间
    renderClosetime() {
      let m = Number.parseInt(this.Closetime / 60);
      let s = Number.parseInt(this.Closetime - m * 60);

      this.cmm = this.checkTime(m);
      this.css = this.checkTime(s);
    },

    // 新判断开奖时间
    renderBantime() {
      let m = Number.parseInt(this.Bantime / 60);
      let s = Number.parseInt(this.Bantime - m * 60);

      this.mm = this.checkTime(m);
      this.ss = this.checkTime(s);
    },

    checkTime(num) {
      if (num < 10) {
        num = "0" + num;
      }
      return num;
    },
  },
};
</script>
<style  >
.dongtai{
  padding: 0 8px;
  height: 25px;
  line-height: 25px;
  display: inline-block;
  background: #fff;
  border-radius: 12px;
}
.jump {
  animation: jump 2s ease-in-out infinite;
  /* animation-iteration-count: 2; */
}
@keyframes jump {
  0% {
    /* transform: translateY(0); */
    transform: scale(1);
    color: #eb7a7a;
    
  }
  50% {
    /* transform: translateY(-1px); */
    transform: scale(1.1);
    /* color: #f4f403; */
    color: yellow;
  }
  100% {
    /* transform: translateY(1); */
    transform: scale(1);
    color: rgb(84, 240, 240);
    
  }
}
.jiashipan {
  display: inline-block;
  height: 22px;
  line-height: 22px;
  padding: 0px 10px;
  color: #c80000;
  background: #ffff00;
  border-radius: 2px;
}
</style>